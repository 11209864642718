import React from "react";

import "../styles/global.css";
import NoHeroLayout from "../layouts/NoHeroLayout";

const TermsAndConditions = () => {
  return (
    <NoHeroLayout seo={{ title: "Terms And Conditions", url: "https://beckcollegeprep.com/terms-and-conditions"}}>
      <div style={{marginTop: '8rem'}} className="bcp page" id="terms-and-conditions">
        <div>
          <h1>Terms &amp; Conditions</h1>
        </div>

        <section style={{margin: '4rem 0rem'}}>
          <h3>RECITALS</h3>
          <p>
            <strong>WHEREAS,</strong> the Company is engaged in the business of
            tutoring, test preparation, and college counseling for Students from
            Elementary School through University Level&nbsp; (“Services”); and
          </p>
          <p>
            <strong>WHEREAS,</strong> the Company has confidential information,
            which includes, but is not limited to its teaching processes and
            methodologies that warrant protection and are considered protected
            confidential and intellectual property pursuant to State, Federal
            and International law; and
          </p>
          <p>
            <strong>WHEREAS,</strong> the Company has invested a significant
            amount of time and effort in hiring, vetting, and training each its
            Employee/Tutor (“Employee/Tutor”); and
          </p>
          <p>
            <strong>WHEREAS,</strong> the Parent/Student agrees and acknowledges
            that each employee has signed a non-compete agreement with Company
            and Parent/Student are not allowed to solicit any services from
            Employee outside of this agreement; and
          </p>
          <p>
            <strong>WHEREAS,</strong> the Company desires to employ the Employee
            and Employee is willing to accept such employment by the Company, on
            the terms and subject to the conditions set forth in this Agreement.
          </p>
          <p>
            <strong>NOW, THEREFORE </strong>in consideration and receipt and
            sufficiency of which is hereby acknowledged by the Parties, the
            Parties hereby agree and acknowledge and incorporate the above
            mentioned recitals and all of the foregoing provisions:
          </p>
          <p>
            <strong>
              Services provided by the Company will never be conducted in the
              home of Employee/Tutor.{" "}
            </strong>
          </p>
          <h3>SCHEDULED TIME</h3>
          <p>
            <strong>Student:</strong> To ensure that your time is respected, as
            well as the time of our other Students and the Employee/Tutor,
            appointments will promptly begin at their scheduled time, regardless
            of the time of your arrival or when the Student is available to
            begin the session.&nbsp; No adjustment to fees shall be made for
            time lost, because of late arrival by the Student or by early
            termination of session by the Student, nor will the tutor be able to
            extend your appointment to accommodate the loss in time.
          </p>
          <p>
            <strong>Employee/Tutor:</strong> Any lost time because of the late
            arrival of the Tutor shall be compensated for by extending a lesson
            by mutual agreement and by such amount of time that was lost.
          </p>
          <h3>SELECTION OF TUTOR</h3>
          <p>
            <strong>Employee/Tutor:</strong> The Company will select a Tutor
            based on the Student’s needs, which are based in part on the answers
            to the Parent/Student questionnaire.&nbsp; In addition, the Company
            screens each Employee/Tutor based on their knowledge, experience,
            professionalism and personality, and we tried to properly match the
            Student’s needs with the Tutor’s knowledge, experience and ability.
          </p>
          <p>
            In addition, to the foregoing, as part of Company’s Service, the
            Company thoroughly checks all references and runs a criminal
            background check on each Employee/Tutor.
          </p>
          <p>
            <strong>Substitution of Employee/Tutor:</strong> Though the Company
            will try not to substitute any Employee/Tutor once assigned to a
            Student; the Company reserves the right to substitution any
            Employee/Tutor for any reason, including, but not limited to
            performance of the Tutor and/or Student’s needs, medical emergency
            of the Employee/Tutor and/or for any reason that the Company
            believes in its sole discretion substitution is necessary.
          </p>
          <h3>PAYMENT OF SERVICES</h3>
          <p>
            <strong>Payment for Services. </strong>The Parties, which includes
            any party responsible for&nbsp;payment, if different than Student,
            hereby acknowledge and agree that as consideration for the Services
            provided by Company to Student, Parent/Student shall pay to Company
            ___ per session.&nbsp; (“Consideration”), which has been designated
            in the Parent/Tutor Agreement.
          </p>
          <p>
            <strong>Payment Schedule. </strong>Company hereby agrees to provide
            to Student/Parent invoices for the Services performed by Company on
            a biweekly basis for payment. Student/parent hereby acknowledges and
            agrees to pay any and all invoices submitted by Company to
            Student/Parent within 14 days of receipt of such invoices by
            Student/Parent.
          </p>
          <p>
            The Parties must provide the Company a working credit card to keep
            on File. If the Student/Parent fail to pay in accordance with any
            provision of this agreement, then the Company will charge the credit
            card that is on file.
          </p>
          <p>
            The Parties hereby acknowledge and agree that Student/Parent shall
            pay any and all invoices received by Company either by check,
            certified check, money order, credit card, wire transfer, or by such
            other means as the Parties may agree in writing to the Company.
            Student/Parent should never pay any Employee/Tutor directly.
            However, in the event Student/Parent pays with either wire transfer,
            credit card or Student/Parent agrees to pay for the processing fee
            for the same as well. In addition, Student and/or Parent/Guardian
            agrees that in the event of a dispute between the parties with
            regards to payment or otherwise., Student and/or Parent/Guardian
            agree and forever waives their right to dispute any credit card, or
            wire transfer charge for payments for services rendered by Company
            and agree to resolve any dispute arising out of this agreement
            pursuant to Paragraph titled Dispute Resolution.
          </p>
          <p>
            The Parties hereby acknowledge and agree that any amounts that
            become due and owing under this Agreement will be subject to any
            applicable taxes and interest imposed pursuant to Florida Law.
          </p>
          <p>
            <strong>Out of Pocket Expenses. </strong>The Parties hereby
            acknowledge and agree that&nbsp; Parent/Student shall not be
            responsible for the payment of any of the Company’s out-of-pocket
            expenses incurred by Company in relation to performance of the
            Services set forth herein, unless otherwise agreed by the Parties in
            writing.
          </p>
          <h3>CANCELLATION POLICY</h3>
          <p>
            <strong>Cancellation of Lesson by Student. </strong>The Student may
            cancel any session by giving at least 24 hours prior notice to the
            Employee/Tutor, in which case no fee for the session will be
            incurred. Lessons not attended by the student without giving 24
            hours prior notice to the Employee/Tutor shall be charged at 50% of
            the full rate.
          </p>
          <p>
            <strong>Cancellation of Lesson by Employee/Tutor. </strong>The Tutor
            may cancel lessons by giving 24 hours prior notice to the Student in
            which case no fees shall be incurred. Where a lesson was pre-paid,
            the Tutor shall reschedule the appointment at a time agreeable to
            both parties.
          </p>
          <h3>
            REPRESENTATIONS, WARRANTIES AND OBLIGATIONS BETWEEN THE PARTIES
          </h3>
          <p>
            <strong>Company/Employee/Tutor.</strong> The Tutor undertakes to do
            all preparation prior to lessons and to structure lessons in such a
            way as to optimize time to the benefit of the Student.
          </p>
          <p>
            The Tutor shall keep confidential all information of the Student and
            shall contact other parties involved in the education of the Student
            only if given written permission by the Student and/or
            Parent/Guardian to do so.
          </p>
          <p>
            The Tutor shall at no time be required or obliged to execute
            homework or assignments on behalf of the Student.
          </p>
          <p>
            Weekly summaries and interaction with third parties where necessary.
          </p>
          <p>
            The Company and/or Tutor makes no promises, guarantees or expressed
            and/or implied warranties of any kind with regards to a Student’s
            performance. Effective communication is essential to achieving your
            child’s educational goals. • If at any time you are dissatisfied
            with our services, please notify us so that we can make appropriate
            changes of personnel or techniques. We welcome the opportunity to
            improve our services, but we can fix something that is bothering you
            unless we know about it.
          </p>
          <p>
            The Tutor shall at no time assist with the completion of
            non-standard examinations. This includes, but is not limited to
            “Take Home” tests and online Tests/Quizzes.
          </p>
          <p>
            The Tutor will follow the Beck Method to increase student’s academic
            performance.
          </p>
          <p>
            The Tutor will never allow a Student to enter, ride, or drive in a
            Tutor’s vehicle.
          </p>
          <h3>BEFORE, DURING, AFTER TUTORING SESSION PROCEDURES.</h3>
          <p>
            <strong>Before the Tutoring Session Begins. </strong>The Tutor will
            contact the parent and the student in the same text message or email
            explaining upcoming assignments and/or exams. The Tutor should take
            the guidance of the Parent and/or Student as to which assignments to
            complete in order of priority. Before the Tutor arrives, the Student
            and/or Parent should have all of the material readily available in
            the designated study area.
          </p>
          <p>
            <strong>During the Tutoring Session. </strong>The Tutor will begin
            by giving instruction on assignments in order in which the Parent
            and/or Student dictated in the Pre-Tutoring Session communication.
            The Student and/or Parent should further elaborate on the area of
            the weakness or lack of understanding that the Student is
            experiencing. This allows the Tutor to maximize the amount of time
            needed to provide the student with the proper instruction in the
            target problematic areas.
          </p>
          <p>
            After the Tutor has provided all of the necessary instruction to the
            Student, the Tutor will utilize the Beck Method to evaluate whether
            the Student has mastered the concepts taught in the lesson.
          </p>
          <p>
            The Tutor will continue these procedures through out the tutoring
            session until either the time of the tutoring session has ended or
            all of the assignments mentioned in the Pre-Tutoring Session
            communication have been completed.
          </p>
          <p>
            <strong>After the Tutoring Session Ends. </strong>Once the Tutor has
            completed the tutoring session, the Tutor will send either a text
            message or email to the Parent and/or Student, which communicates
            the assignments that have been completed and the assignments that
            require further attention addressed in pre-tutoring session
            communication, along with a brief description of the Student’s
            behavior which includes but is not limited to the student’s
            concentration, motivation, retention, etc.
          </p>
          <p>
            If any stages in the Before, During, and After Tutoring Session
            Procedure has not been followed, then a Beck College Prep manager
            must be notified immediately. The Beck College Prep manager will
            then further investigate the issue and ameliorate the situation by
            communicating with the party not complying for the procedure to
            create a solution to help ensure the ideal tutoring session.
          </p>
          <p>
            <strong>Student. </strong>The Student undertakes to assist the Tutor
            in identifying problem areas in which the Student needs specific
            tutoring.
          </p>
          <p>
            The Student agrees that assignments, exercises or homework form an
            integral part of tutoring and undertakes to complete such work
            timely.
          </p>
          <p>
            To make adequate progress and ensure eventual success, a student
            must come to each tutoring session with all required materials,
            including awareness of assignments. If the student is unprepared,
            the session may be reduced in length or cancelled at the discretion
            of the tutor and will be billed at the normal rate.
          </p>
          <p>
            Notify Company and Tutor of any special instructions or safety
            concerns when attending the session.
          </p>
          <h3>Indemnification, LIMITATION OF LIABILITY</h3>
          <p>
            <strong>Minors.</strong> Where the Student is a legal minor, the
            Parent/Guardian shall enter into this Agreement on behalf of the
            Student and shall accept and agree to all the terms and conditions
            contained herein on behalf of the Student and will also be
            personally responsible for all Services and Fees set forth in this
            agreement, including, but not limited to any violation of the
            Restrictive Covenants.&nbsp; Nothing herein shall be construed as
            limiting Company’s right to seek payment from either the Student or
            Parent, without notice or any additional formality, which may
            normally be required as a condition precedent to seeking third party
            payment from the Parent/Guardian.&nbsp; Student and Parent/Guardian
            herein forever waive any such right to notice and/or any condition
            precedents that maybe required pursuant to law in seeking payment
            from either Student and/or Parent/Guardian first. Further, nothing
            herein shall be construed as relieving Student of any responsibility
            for payment as consideration for services received by Company for
            any reason.
          </p>
          <p>
            <strong>Company’s Employee/Tutors:</strong> is responsible for any
            act, action or conduct of the Employee/Tutor within the scope of
            services provided in this Agreement only; Company is not responsible
            for the acts, actions or conduct of the Tutor, Student,
            Parent/Guardian or Third Party that is outside of the scope of the
            Company’s Services.
          </p>
          <p>
            The Parties agree that in the event the Company is liable or must
            indemnify either the Student, Parent/Guardian or any Third Party for
            any reasons, whether in contract, tort, or otherwise (including
            negligence), arising directly out of or in connection with this
            Agreement or the performance or observance of Company’s obligations
            under this Agreement and every applicable part hereof shall be
            limited to the aggregate amount of the Consideration.&nbsp; In no
            event shall Company be liable for including, but not limited to any
            indirect or consequential loss or damages.
          </p>
          <p>
            Nothing contained in this agreement, shall serve to limit or exclude
            any Party’s liability for any intentional, criminal or grossly
            negligent acts arising from the Party’s own act or conduct.
          </p>
          <h3>Non- Solicitation AND Confidentiality.</h3>
          <p>
            <strong>Non-Solicitation Covenant: </strong>Student and
            Parent/Guardian agrees that for a period of twenty-four (24) months,
            which commences upon the last day in which any Employee/Tutor of
            Company provides his or her last Service, Student and/or
            Parent/Guardian will not directly or indirectly solicit, induce,
            recruit, encourage or otherwise endeavor to cause or attempt to
            cause any Employee of the Company to terminate or reduce their
            relationship with the Company for any reason.
          </p>
          <p>
            <strong>
              Knowledge of existing non compete agreement with Employee.&nbsp;{" "}
            </strong>
            Student and/or Parent acknowledges that the Employee/Tutor has an
            existing non-compete agreement in place with Company and that any
            solicitation for additional services or services outside of the
            scope of the Agreement with Company is prohibited, including, but
            not limited to extending tutoring sessions, working off schedule
            hours, etc.
          </p>
          <p>
            <strong>Finder’s Fee. </strong>Should Employee/Tutor commence
            working, whether directly, indirectly or through a third party
            associate of Student and/or Parent in any capacity outside of the
            scope of Company’s Services during any period of time in which this
            agreement is in enforce or for a period of twenty four (24) months
            after the termination of this Agreement for any reason without
            Company’s prior written consent, Student and/or Parent agree to pay
            Company a finder’s fee of&nbsp; five thousand dollars ($5000).
          </p>
          <p>
            <strong>Confidentiality</strong>. During employment, and anytime
            thereafter, Student and/or Parent agree that his/she shall not
            disclose to anyone any Confidential Information of the Company’s.
            The Company possesses, or may in the future possess, certain
            valuable trade secrets, proprietary data and confidential
            information that has been created, discovered or developed by, or
            has otherwise become known to, the Company (including, without
            limitation, information created, discovered, developed by or made
            known to Student and/or Parent in the course of his or her Services
            by the Company), or in which the Company has proprietary rights
            (whether by license, assignment or otherwise).&nbsp; All such
            information, whether known on the date hereof or become known
            hereafter, is collectively referred to as “Confidential
            Information”, which term shall include, without limitation, trade
            secrets, unpublished patent applications, processes, formulae, data,
            know-how, developments, designs, improvements, inventions,
            techniques, laboratory notebooks, marketing, research and
            development, clinical and business development plans, new products,
            software, software documentation, contract terms, licenses, prices
            and costs; provided, however, that “Confidential Information” shall
            not include information (i) in the public domain by publication
            through no fault of the Student and/or Parent or (ii) lawfully
            received by the Student and/or Parent from a third party who was
            under no obligation of confidentiality with respect thereto.
          </p>
          <p>
            <strong>Injunctive Relief/Irreparable Harm to the Company.</strong>{" "}
            Student and Parent/Guardian acknowledges that the specialized nature
            of Company’s Employee’s, knowledge of the Company’s proprietary
            information, trade secrets and other intellectual property are such
            that a breach of Employee’s covenant not to compete and/or the
            Student’s and Parent/Guardian’s covenant not to solicit contained in
            this Section of this Agreement would inflict unique and irreparable
            harm upon the Company and that the Company shall be entitled, in
            addition to its other rights and available remedies, to enforce, by
            injunction or decree of specific performance, set forth in this
            Section. &nbsp; In addition, to the remedies available law and this
            Agreement, Employee agrees that any such violation of this provision
            or any provision of Section 4, 5 or 6, Employee agrees that it would
            be difficult to determine the loss to Company and therefore in
            additional to Company’s right to seek injunctive relief, specific
            performance and other monetary damage, Employee agrees to pay
            liquidated damages of $250 per day for each day in which Employee is
            in violation of any of the restrictive covenants set forth herein.
          </p>
          <h3>TERMINATION OF AGREEMENT</h3>
          <p>
            <strong>Termination. </strong>This Agreement may be terminated by
            either party, at any time by giving the other party 7 days prior
            written notice.
          </p>
          <p>
            Either Party may terminate this Agreement effective immediately,
            upon written notice to the other Party or if any of the following
            events occur:
          </p>
          <p>
            If either Party to this Agreement is in breach of any of its
            obligations contained in this Agreement and such breach is not
            remedied within 14 days of written notice from the other Party;
            or&nbsp; if Student and/or Parent breaches any of the provisions set
            forth in this agreement.
          </p>
          <p>
            Any termination of this Agreement shall not affect any of the
            accrued rights or liabilities of either Party to this Agreement and
            shall not affect the coming into force or the continuance in force
            of any provision contained herein that is expressly or by
            implication intended to come into or continue in force on or after
            such termination which shall include, but is not limited to the
            restrictive covenants set forth in Paragraph 5.
          </p>
          <h3>DISPUTE RESOLUTION</h3>
          <p>
            The Parties hereby agree that any disputes or disagreements
            respecting the negotiation, performance, or interpretation of this
            Agreement, shall be resolved as follows:
          </p>
          <p>
            The Parties shall attempt to resolve any dispute by dealing with
            each other directly first;
          </p>
          <p>
            <strong>Disputes related payment and services only. </strong>
            In the event that the Parties cannot resolve the dispute within 30
            days after the dispute arises, then each of the Parties shall,
            within 7 days thereafter, appoint an arbitrator, and the two
            arbitrators so appointed will, within 7 days after their
            appointments, agree upon the appointment of a third arbitrator, who
            must be an arbitrator with educational and teaching experience and a
            non-attorney.&nbsp; If the Parties fail to appoint an arbitrator
            within the 7 day time limit outlined above, then that Party shall be
            deemed to have forfeited its right to appoint an arbitrator, and the
            arbitrator appointed by the other Party shall appoint one other
            arbitrator, and both of those arbitrators shall agree upon the
            appointment of a third arbitrator.&nbsp; Once all three arbitrators
            have been appointed, the Parties shall present the dispute to the
            three arbitrators at the earliest time or times designated by the
            three arbitrators, and the three arbitrators shall resolve the
            dispute on the basis of a majority decision.&nbsp; The Parties agree
            that the Florida Rules of Civil Procedure and Evidence apply to all
            motions and final hearing during Arbitration. The Parties agree to
            split the cost of arbitration equally without prejudice to the
            prevailing party’s rights to collect all cost and fees associated
            with the arbitration, including, but not limited to any arbitration
            fees, all arbitrator fees, witness expenses, court reporter and
            attorney fees.&nbsp; In the event, one of the Parties is unable or
            unwilling to pay for their share of arbitration fees either prior or
            at any time during the arbitration proceedings, then the other Party
            shall have the right to move the proceedings to any State or Federal
            Court within Miami-Dade County and forever waives their right to any
            defense and/or right to compel further arbitration by the other
            party. Further all such fees to date incurred by the other Party in
            the Arbitration shall be reimbursed to the non-breaching Party
            immediately, regardless of whether or not the non-breaching Party
            ultimately wins in State or Federal Court.&nbsp; Such decision of
            the arbitrators shall be final and binding on the Parties, unless
            there has been an objection to a Discovery ruling and/or application
            of the Florida Rules of Procedure and Evidence on record (record
            shall be by any means during the proceedings including, but not
            limited to by motion, transcript, ruling, letter of record, etc.)
            during any portion of the Arbitration proceeding and/or Final
            hearing.&nbsp; In the event any objections as set forth above
            occurs, the Parties shall have the right to appeal the arbitrators’
            decision to a Court of competent jurisdiction for appellate review.
            Should no such objection be raised, then neither Party shall have
            any further right of appeal to any other party, body, authority, or
            court, except those provided for in the Arbitration Organization
            rules or Federal Arbitration Act exceptions.
          </p>
          <p>
            In addition, the Parties agree to use either AAA or JAMS in the
            event of a conflict. In the event of a dispute, as to which of the
            following organizations the Parties will use, the Parties agree to
            use the organization with the least administrative cost for the
            proceedings.
          </p>
          <p>
            If disputes that arise due to non-payment of any of the provisions
            in this agreement, then the matter should be resolved in a court of
            competent jurisdiction.
          </p>
          <h3>Survival, Assignment and Benefit.</h3>
          <p>
            This Agreement may not be assigned by Student and/or Parent.&nbsp;
            The terms of this Agreement shall survive the termination of the
            Services to the Student by the Company and shall inure to the
            benefit of and be binding upon the Parties hereto and their
            respective successors, heirs and assigns.
          </p>
          <h3>Section&nbsp;Headings.</h3>
          <p>
            The section headings contained herein are for the purposes of
            convenience only and are not intended to define or limit the
            contents of said sections.
          </p>
          <h3>Attorney fees.</h3>
          <p>
            In the event of a breach by either Party including, but not limited
            to this agreement, the non prevailing party shall be responsible for
            the prevailing party’s&nbsp; expenses, including, without
            limitation, reasonable attorneys’ fees, court reporter and court
            costs, incurred in enforcing any of the covenants of this Agreement
            and any appeals if applicable.
          </p>
          <h3>Notices.</h3>
          <p>
            Any notices that may be required pursuant to this agreement or any
            obligation arising there from, shall be sent to the following
            addresses listed below.&nbsp; Should there be any change in the
            information listed below after the execution of this Agreement, that
            Party is responsible for notifying Beck College Prep.
          </p>
          <h3>Governing Law.</h3>
          <p>
            This Agreement shall be governed by and construed in accordance with
            the laws of the State of Florida without regard to any conflict of
            law principles of any other jurisdiction. Further, any disputes or
            action that may rise as a resulting of this Agreement between the
            Parties shall be brought exclusively in either a AAA or JAMS office
            and/or Court of competent jurisdiction in Miami-Dade County only.
          </p>
          <h3>Further Assurances.</h3>
          <p>
            Each Party hereto shall cooperate and shall take such further action
            and shall execute and deliver such further documents and provide any
            information that may reasonably necessary and/or requested by the
            other Party in order to carry out the provisions and purposes of
            this Agreement.
          </p>
          <h3>Counterparts.</h3>
          <p>
            This Agreement may be executed in counterparts, all of which taken
            together shall be deemed one original.
          </p>
          <h3>Severability.</h3>
          <p>
            Each provision of this Agreement shall be treated as a separate and
          </p>
          <p>
            independent clause, and the unenforceability of any one clause shall
            in no way impair the enforceability of any of the other clauses
            herein.&nbsp; The Parties agree that should any portion of this
            Agreement, including, but not limited to the Non-solicitation and
            Confidentiality provision of Section 5, be found to be unreasonable
            and/or unenforceable as written, the Parties agree to allow the
            Arbitration Panel and/or Court to modify the unenforceable provision
            to the extent necessary and sufficient to make the provision
            enforceable and protect the Company’s interest.
          </p>
          <h3>Entire Agreement And Drafting of this Agreement.</h3>
          <p>
            This Agreement supersedes all prior and contemporaneous agreements
            and understandings between the Parties hereto, oral or written, and
            may not be modified or terminated except in writing and executed by
            both Parties. &nbsp; The drafting of this Agreement was the product
            of both Parties, therefore any ambiguity or interpretation of any
            contract provision or language that may arise shall not be held
            against either party.
          </p>
          <h3>Amendments and Waiver.</h3>
          <p>
            This Agreement may not be altered, modified or waived in whole or in
            part, except in writing, signed by the Student and/or Parent and the
            Company. No failure or delay by either party in exercising any
            rights, power, or remedy under this Agreement shall operate as a
            waiver of any such right, power, or remedy.&nbsp; The Student and/or
            Parent agrees that any change or changes in the scope of Services,
            including, but not limited to the duration of the Services after the
            signing of the Agreement shall not affect the validity or scope of
            this Agreement and all remaining and/or unmodified provisions will
            remain in full force, unless otherwise amended or modified in
            accordance with this Agreement.
          </p>
        </section>
      </div>
    </NoHeroLayout>
  );
};

export default TermsAndConditions;
